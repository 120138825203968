import React, {useEffect, useRef, useState} from 'react';
import * as PropTypes from "prop-types";
import * as classes from 'classnames'

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Radio = (props) => {
  let { id, name, className, value, onChange, label, style = {}, } = props;

  let checked = props.defaultChecked ? props.defaultChecked : false;

  let [defaultChecked, setDefaultChecked] = useState(false);
  const prevChecked = usePrevious(checked);

  // on mount
  useEffect(() => {
    setDefaultChecked(checked);
  }, []);

  // on update
  useEffect(() => {
    if (prevChecked !== checked) {
      setDefaultChecked(checked);
    }
  }, [checked, prevChecked]);

  let [highlightDefault, setHighlightDefault] = useState(false);

  let update = function (e) {
    if (defaultChecked === true && e.target.checked) {
      setHighlightDefault(true)
    } else {
      setHighlightDefault(false)
    }
    if (onChange) {
      onChange(name, value, e)
    }
  };

  return (
    <div className={classes("checkbox_radio_container ", className, { 'highlight_radio': checked })} style={style}>
      <input type="radio" id={id} name={name} className="required"
             value={value}
             onChange={(e) => {
               update(e)
             }}/>
      <label className={classes("radio")} htmlFor={id}/>
      <label htmlFor={id} className="wrapper">{label}</label>
    </div>
  );
};

Radio.propTypes = {
  field: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.any,
  defaultChecked: PropTypes.bool
};

export default Radio;
