import React, { useEffect, useState, useRef } from 'react';
import * as classes from "classnames";
import { usePrevious } from "../../lib/custom-hooks";
import { CountryDropdown, RegionDropdown,} from 'react-country-region-selector';
import { Row, Col } from "reactstrap";
import Countries from "../../_mock/countries";
import { isEmpty } from "../../helpers/utils";


const CountrySelect = (props) => {
  let {
    staleProp,
    countries,
    className,
    onChange,
    onBlur,
    onFocus,
    id,
    name,
    value = 'ghana',
    error = false,
    flag = true
  } = props;


  // let prevCountries = usePrevious(countries);
  // countries = countries ? countries : Countries;
  let [ inFocus, setInFocus ] = useState(false);
  let [ mouseOver, setMouseOver ] = useState(false);
  let whitelist = [];
  if (countries) {
    if (Array.isArray(countries)) {
      whitelist = countries.map((country) => {
        if (typeof country === 'string') {
          return country;
        }
        return country.isoCode;
      });
    } else {
      whitelist = Object.values(countries).map(({ isoCode }) => isoCode);
    }
  }

  // console.log({ countries, whitelist });

  let [ country, setCountry ] = useState(value);
  // const prevStaleProp = usePrevious(staleProp);

  // on update
  // useEffect(() => {
  //   console.log({ countries, prevCountries });
  // }, [ countries, prevCountries ]);


  let handleChange = function (value) {
    setCountry(value);
    typeof onChange === "function" && onChange(name, value)
  };

  let handleFocus = function (value) {
    setInFocus(true);
    typeof onFocus === "function" && onFocus(name, value)
  };

  let handleBlur = function (value) {
    setInFocus(false);
    typeof onBlur === "function" && onBlur(name, value)
  };

  let fieldProps = {
    ...props,
    ...(country && {defaultOptionLabel: country}),
    className : className + " border-0",
    value : country,
    onChange : handleChange,
    onBlur : handleBlur,
    onFocus : handleFocus
  };

  let countryFlag = Countries[country.toLocaleLowerCase()]

  return (<Row className={classes("form-control-wrapper select m-0",
    { 'border-danger' : error, focus : inFocus })}>
    {
      (!isEmpty(country) && !isEmpty(countryFlag)) ?
        (
          <div className="col-auto px-2 d-flex">
            <img className={'my-auto country-flag'} height="20"
                 src={`/img/flags/${countryFlag.flag}.svg`}
                 alt={`${country} Flag`}/>
          </div>
        ) : null
    }
    <Col className={'p-0'}>
      {/*<CountryDropdown showDefaultOption whitelist={["GH", "GB"]} {...fieldProps}/>*/}
      <CountryDropdown showDefaultOption whitelist={whitelist} {...fieldProps}/>
    </Col>
  </Row>);
};

export default CountrySelect;

export const CountryRegionSelect = (props) => {
  let { staleProp, country = 'Ghana', className, onChange, onBlur, onFocus, id, name, value = '', flag = true } = props;

  let [ region, setRegion ] = useState(value);
  const prevStaleProp = usePrevious(staleProp);

  // on mount
  useEffect(() => {
  }, []);

  // on update
  useEffect(() => {
  }, [ staleProp, prevStaleProp ]);


  let handleChange = function (value) {
    // console.log({name, value});
    setRegion(value);
    typeof onChange === "function" && onChange(name, value)
  };

  let handleFocus = function (value) {
    // console.log({name, value});
    typeof onFocus === "function" && onFocus(name, value)
  };

  let handleBlur = function (value) {
    // console.log(value);
    typeof onBlur === "function" && onBlur(name, value)
  };

  let fieldProps = {
    ...props,
    className,
    country,
    value : region,
    onChange : handleChange,
    onBlur : handleBlur,
    onFocus : handleFocus
  };

  return <Col className={'p-0'}><RegionDropdown showDefaultOption={true} {...fieldProps}/></Col>;
};
