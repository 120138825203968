const countries = {
  ghana : {
    name : 'Ghana',
    code : 'GH',
    isoCode : 'GH',
    flag : 'ghana',
    currency : 'GHS',
    callingCode : '+233',
    primary : true,
    foreground : '#fff',
    color : '#fff',
    background : '#b88b02',
    border : '#007e00',
    disabled : false
  },
  'united states' : {
    name : 'United States of America',
    nickName : 'united states',
    flag : 'us',
    code : 'US',
    isoCode : 'US',
    currency : 'USD',
    callingCode : '+1',
    color : '#fff',
    foreground : '#fff',
    background : '#0f61fd',
    border : '#f00',
    disabled : false
  },
  'united kingdom' : {
    name : 'United Kingdom',
    flag : 'uk',
    code : 'GB',
    isoCode : 'GB',
    currency : 'GBP',
    callingCode : '+44',
    color : '#012169',
    foreground : '#012169',
    background : '#fff',
    border : '#f00',
    disabled : false
  },
  kenya : {
    name : 'Kenya',
    flag : 'kenya',
    code : 'KE',
    isoCode : 'KE',
    currency : 'KES',
    callingCode : '+254',

    color : '#fff',
    foreground : '#fff',
    background : '#700202',
    border : '#000',
    disabled : false
  },
  nigeria : {
    name : 'Nigeria',
    flag : 'nigeria',
    code : 'NG',
    isoCode : 'NG',
    currency : 'NGN',
    callingCode : '+234',
    color : '#fff',
    foreground : '#fff',
    background : '#149d00',
    border : '#012b01',
    disabled : false
  },
  'south africa' : {
    name : 'South Africa',
    flag : 'south-africa',
    code : 'ZA',
    isoCode : 'ZA',
    currency : 'ZAR',
    callingCode : '+27',
    color : '#ffb915',
    foreground : '#ffb915',
    background : '#000c8a',
    border : '#007847',
    disabled : false
  },
  'united arab emirates' : {
    name : 'United Arab Emirates',
    flag : 'uae',
    code : 'AE',
    isoCode : 'AE',
    currency : 'AED',
    callingCode : '+971',
    color : '#fff',
    foreground : '#fff',
    background : '#00732f',
    border : '#000',
    disabled : false
  }
}


export const getCountryFlagByCode = function (code) {
  const country = Object.values(countries)
    .filter(({code : isoCode}) => isoCode.toLocaleLowerCase() === code.toLocaleLowerCase())[0];

  return country ? country.flag : ""
}

export const getCountryByCode = function (code) {
  return Object.values(countries)
    .filter(({code : isoCode}) => isoCode.toLocaleLowerCase() === code.toLocaleLowerCase())[0];
}

export const getCountryByName = function (name) {
  return Object.values(countries)
    .filter(({ name : countryName }) => countryName.toLocaleLowerCase() === name.toLocaleLowerCase())[0];
}
export const getCountryCodeByName = function (name) {
  const country = Object.entries(countries).filter(([key, country ]) => key === name.toLocaleLowerCase())[0];
  return country ? country[1].isoCode : ""
}

export const getCountryByKeyName = function (keyName) {
  const country = Object.entries(countries).filter(([key, country]) => keyName.toLocaleLowerCase() === key)[0][1];
  return country ? country[1] : ""
}

export const getAllCountryCodes = function () {
  return Object.values(countries).map(({ code }) => code)
}

export default countries;


