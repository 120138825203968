import React from 'react';
import * as PropTypes from "prop-types";

// import './_CheckBox.scss'

const CheckBox = React.forwardRef((props, ref) => {
  let { name, id, value, tabIndex = 10, className = '', disabled = false, checked, onChange, onBlur, } = props;

  // console.log(ref);
  return (
    <div className={"checkbox_radio_container " + className}>
      <input type="checkbox" id={id} ref={ref} name={name} className="required" value={value}
             tabIndex={tabIndex}
             defaultChecked={checked} disabled={disabled}
             onChange={(e) => {
               if (onChange) {
                 onChange(name, e.target.checked, e)
               }
             }}
             onBlur={(e) => {
               if (typeof onBlur === 'function') {
                 onBlur(name, e.target.checked, e)
               }
             }}
    />
      <label className="checkbox" htmlFor={id}/>
      <label htmlFor={id} className="wrapper">{props.label}</label>
    </div>);
});

CheckBox.propTypes = {
  tabIndex : PropTypes.number,
  field : PropTypes.any,
  id : PropTypes.string,
  name : PropTypes.string,
  value : PropTypes.any,
  label : PropTypes.string,
  onChange : PropTypes.func
};

export default CheckBox;
