import { Col, Row } from "reactstrap";
import classes from "classnames";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { EyeClosedIcon, EyeOpenIcon } from "@modulz/radix-icons";
import ReCAPTCHA from "react-google-recaptcha";
import CountrySelect, { CountryRegionSelect } from "../CountrySelect";
import Radio from "../Radio/Radio";
import CheckBox from "../CheckBox/CheckBox";
import PhoneInput from "../PhoneInput";
import { isEmpty } from "../../helpers/utils";
import DatePicker from "../DatePicker/DatePicker";

function PasswordField (props) {
  let [inFocus, setInFocus] = React.useState(false);
  let [mouseOver, setMouseOver] = React.useState(false);
  return <Row className="m-0">
    <Col className={"p-0"}>

      <input {...props.fieldProps}
             onFocus={(e) => {
               setInFocus(true);
               typeof props.onFocus === "function" && props.onFocus()
             }}
             onBlur={(e) => {
               setInFocus(false);
               typeof props.onBlur === "function" && props.onBlur()
             }}
             onMouseOver={(e) => setMouseOver(true)}
             onMouseOut={(e) => setMouseOver(false)}
      />
    </Col>
    {
      props.type === "password" &&
      <div
        className={classes("password-view-toggle form-control-addon col-auto d-flex border-left-0 pl-1 pr-2 cur-pointer",
          { "border-danger" : props.error, focus : inFocus , hover : mouseOver })}
        onClick={props.onClick
        }>
        {
          !props.showPassword ? <EyeClosedIcon className={"m-auto"}/> : <EyeOpenIcon className={"m-auto"}/>
        }
      </div>
    }
  </Row>;
}

PasswordField.propTypes = {
  fieldProps : PropTypes.shape({
    ref : PropTypes.any,
    onChange : PropTypes.func,
    name : PropTypes.string,
    className : PropTypes.any,
    id : PropTypes.string,
    type : PropTypes.string,
    value : PropTypes.string,
    required : PropTypes.bool
  }),
  type : PropTypes.string,
  error : PropTypes.bool,
  onClick : PropTypes.func,
  showPassword : PropTypes.any
};

const RenderInput = function RenderInput (props) {
  let {
    name = '',
    id = '',
    label = '',
    tabIndex = null,
    autoFocus = false,
    showLabel = true,
    type = "text",
    value = '',
    formErrors = {},
    showPassword,
    onToggleShowPassword,
    onChange,
    onFocus,
    onBlur,
    labelClassName = "",
    required = true,
    disabled = false,
    error = false,
    errorMessage = ''
  } = props;

  const inputEl = useRef(null);
  // on mount
  useEffect(() => {
    let e = { target : inputEl };

    // let's make sure if that the React state is updated with the current value of the input field if onLoad

    if (inputEl.current && !isEmpty(inputEl.current.value)) {
      onChange(name, inputEl.current.value, inputEl.current,)
    } else if (value) {
      onChange(name, value, inputEl.current)
    }
  }, []);

  if (id === "") {
    id = name;
  }

  let inputField = null;

  // console.log({ tabIndex });
  let commonFieldProps = {
    ref : inputEl,
    id,
    name,
    type,
    required,
    value,
    ...(tabIndex && { tabIndex }),
    ...(autoFocus && { autoFocus }),
    className : classes("form-control msisdn text-left", {
      'has-input' : value !== '',
      'border-danger' : error, disabled, "f-error" : formErrors[name] !== undefined
    }),
    onChange : (e) => {
      onChange(name, e.target.value, e,)
    }
  };

  switch (type) {
    case ("text"): {
      inputField = <input {...commonFieldProps} />
      break;
    }
    case ("longtext"): {
      inputField = <textarea {...commonFieldProps}/>
      break;
    }
    case ('radio'): {
      let { options } = props;
      let radioSpan = options.length > 2 ? 'auto' : 6;

      inputField = <Row>
        {
          options.map(({ label, value }, idx) => {
            let fieldProps = {
              ...commonFieldProps,
              label,
              value,
              onBlur,
              onChange,
              id : name + idx,
              key : `rad_${name}_${idx}`,
              className : classes(`col-${radioSpan}`, { error, 'pr-1' : radioSpan === 'auto' })
            };
            return <Radio {...fieldProps}/>
          })
        }
      </Row>
      break;
    }
    case "checkbox": {
        let fieldProps = {
          ...commonFieldProps,
          className: classes(`col-${12} px-0`, { error,  }),
          defaultChecked: false,
          id: name,
          label,
          onBlur,
          onChange,
          value,
        };
        inputField = <CheckBox key={`cbd_${name}`} {...fieldProps} onChange={onChange}/>;

      break;
    }
    case ("country"): {
      let { country, countries } = props;

      let fieldProps = {
        ...commonFieldProps,
        onChange,
        countries,
        className : classes("form-control msisdn text-left has-input", {
          'border-danger' : error, disabled, "f-error" : formErrors[name] !== undefined
        }),
      }
      inputField = <CountrySelect {...fieldProps}/>
      break;
    }
    case ("phone"): {
      let { country, countries, formData } = props;
      // console.log({country, cc : 'cc', props});
      let fieldProps = {
        ...commonFieldProps,
        error,
        onChange,
        country : formData["country"],
        // countries,
        className : classes("form-control msisdn text-left has-input", {
          'border-danger' : error, disabled, "f-error" : formErrors[name] !== undefined
        }),
      }
      inputField = <PhoneInput {...fieldProps}/>
      break;
    }
    case ("country-region"): {
      let { country, countries } = props;
      let fieldProps = {
        ...commonFieldProps,
        onChange,
        country,
        countries,
        className : classes("form-control msisdn text-left has-input", {
          'border-danger' : error, disabled, "f-error" : formErrors[name] !== undefined
        }),
      }
      inputField = <CountryRegionSelect {...fieldProps}/>
      break;
    }
    case "date": {
      let { maxDate, startDate } = props;
      let fieldProps = {
        ...commonFieldProps,
        defaultDate: value,
        label,
        onChange,
        className : '',
        maxDate,
        startDate,
      };
      inputField = <DatePicker {...fieldProps}/>;
      break;
    }
    default : {
      let elType = type === "password" && showPassword ? "text" : type;

      let fieldProps = {
        id,
        name,
        required,
        ...(tabIndex && { tabIndex }),
        type : elType,
        ref : inputEl,
        value,
        className : classes("form-control text-left ",
          {
            'rounded-right-0' : type === "password",
            'has-input' : value !== '',
            'border-right-0 password' : type === "password",
            'border-danger' : error, disabled, "f-error" : formErrors[name] !== undefined
          }),
        onChange : (e) => {
          onChange(name, e.target.value, e,)
        }

      }

      return (
        <>
          {
            (showLabel === true) && <label htmlFor={id} aria-details={id} className={labelClassName}>
              {label} {required && <span className="text-danger">*</span>}
            </label>
          }
          <PasswordField fieldProps={fieldProps} type={type} onFocus={onFocus} onBlur={onBlur} error={error} onClick={(e) => {
            if (typeof onToggleShowPassword === "function") {
              onToggleShowPassword()
            }
          }} showPassword={showPassword}/>
        </>
      );
    }
  }

  return (
    <Row className="m-0">
      <Col className={'p-0'}>
        {
          (showLabel === true) && <label htmlFor={id} aria-details={id} className={labelClassName}>
            {label} {required && <span className="text-danger">*</span>}
          </label>
        }
        {inputField}

      </Col>
    </Row>
  );
}

export function FormField (props) {
  let {
    footer = null, footerClassName = "", errorMessage = '', showLabel = true, formData = {}, columnId = 0,
    rowLength = 1, colClassName = "",
  } = props;

  if (!footer && errorMessage !== "") {
    footer = <small className={"text-danger " + footerClassName}>{errorMessage}</small>
  } else if (typeof footer === 'string') {
    footer = <small className={classes("text-muted", footerClassName)}>{footer}</small>
  }
  // console.log({fprops : props});
  return (
    <Col className={classes(rowLength > 1 ? columnId === 1 ? 'px-0' : 'px-0' : '', colClassName)}>
      <div className={classes("form-group position-relative", { "mb-2" : showLabel, "mb-0" : !showLabel })}>
        <div className="input-wrapper">
          <RenderInput {...props}/>
          {footer}
        </div>
      </div>
      <style jsx>
        {`
          input {
            &.password {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }

          .password-view-toggle {
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        `}
      </style>
    </Col>
  );
}

/**
 *
 * @param formFields
 * @param formData
 * @param formErrors
 * @param showPassword
 * @param toggleShowPassword
 * @returns {*}
 * @constructor
 */
export const RenderForm = function (props) {

  let { formFields, formData = {}, formErrors = {}, showPassword = false, toggleShowPassword } = props;

  return formFields.map((row, r) => {
    let rowContent = null;
    let rowLength = 1;

    if (Array.isArray(row)) {
      rowLength = row.length;

      rowContent = row.map((field, f) => {
        let { type } = field;
        let fieldProps = {
          ...field,
          multiple : true,
          columnId : f,
          rowLength,
          formData,
          key : 'form-row' + r + f,
          value : formData[field.name],
          error : typeof formErrors[field.name] !== "undefined",
          ...(type === "password" && { showPassword, onToggleShowPassword : toggleShowPassword })
        }

        return (
          <div key={f} className={classes('col-12 col-md-6', rowLength > 1 ? f === 1 ? 'pl-3 pl-md-2' : 'pr-3 pr-md-2' : '')}>
            <FormField {...fieldProps}/>
          </div>
        )
      })
    } else {
      if (row.type === 'recaptcha') {
        rowContent = <Col>
          <div className="form-group recaptcha mb-2 position-relative">
            <ReCAPTCHA sitekey={row.siteKey} onChange={row.onChange}/>
          </div>
        </Col>
      } else {
        let formFieldProps = {
          ...row,
          rowLength,
          formData,
          error : typeof formErrors[row.name] !== "undefined",
          value : formData[row.name],
        }
        rowContent = (<FormField {...formFieldProps}/>)
      }
    }

    return <Row key={'form-row' + r} className={"m-0 mb-2"}>{rowContent}</Row>
  })
}

FormField.propTypes = {
  id : PropTypes.string,
  name : PropTypes.string,
  error : PropTypes.bool,
  label : PropTypes.string,
  type : PropTypes.string,
  required : PropTypes.bool,
  onChange : PropTypes.func,
  disabled : PropTypes.bool,
  value : PropTypes.any,
  showPassword : PropTypes.bool,
  onToggleShowPassword : PropTypes.func,
  formErrors : PropTypes.shape({})
};
