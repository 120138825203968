import React, { useState, useRef, useEffect } from 'react';
import classes from 'classnames'
import { isEmpty } from "../../helpers/utils";
import { InputGroup, } from "reactstrap";
import ReactPhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import ReactPhoneInputInput from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'
import {
  getAllCountryCodes,
  getCountryCodeByName,
} from "../../_mock/countries";
import { usePrevious } from "../../lib/custom-hooks";

const PhoneInput = (props) => {
  let {
    className = '', value = '', error, id, name, disabled = false, placeholder = '',
    onChange, onCurrencyChange, onBlur, onFocus,
    countries = getAllCountryCodes(),
    country = 'ghana', countryEditable = false
  } = props;

  // let inputEl = useRef(null);

  let [ phoneNumber, setPhoneNumber ] = useState(value);
  let [ countryCode, setCountryCode ] = useState(getCountryCodeByName(country))

  const prevCountry = usePrevious(country);

  useEffect(() => {
  }, []);

  let handleChange = (value) => {
    if (typeof onChange === 'function') {
      onChange(name, value,)
    }
  }

  let onCountryChange = (value) => {
    if (typeof onChange === 'function') {
      onChange(name, "",)
    }
  }

  let handleBlur = (event) => {
    let inputText = event.target.value.trim();
    if (!isEmpty(inputText)) {
      if (typeof onBlur === "function") {
        onBlur(name, inputText, textInput);
      }
    }
  }

  // let handleFocus = (event) => {}

  // on update
  useEffect(() => {
    if (country !== prevCountry) {
      setCountryCode(getCountryCodeByName(country));
      handleChange(phoneNumber);
    }
  }, [ country, prevCountry ]);

  let inputProps = {
    id,
    name,
    // ref : textInput,
    className : classes("text-input  mw-100", { disabled }),
    placeholder,

    type : "numeric",
    onChange : (e) => {
      handleChange(e)
    },
    onBlur : (e) => {
      handleBlur(e)
    },
    onFocus : (e) => {
      handleFocus(e)
    }
  }

  let fieldProps = {
    inputProps,
    // className : ,
  }
  // let input = React.forwardRef((props, ref) => {
  //   return <input ref={inputEl} {...fieldProps}/>
  // });

  return (<div className={"form-group phone mb-0 input has-input"}>
    <InputGroup className={classes("has-input", { disabled })}>
      {/*{
        !countryEditable && (!isEmpty(country) && <div className="col-auto px-2 d-flex">
          <img className={'my-auto country-flag'} height="20"
               src={`/img/flags/${getCountryFlagByCode(countryCode)}.svg`}
               alt={`${country} Flag`}/>
        </div>)
      }*/}
      {
        countryEditable ?
          <ReactPhoneInput
            withCountryCallingCode
            placeholder="Enter phone number"
            defaultCountry="GH"
            countryCallingCodeEditable={false}
            Country={country}
            countries={countries}
            disabled={disabled}
            value={phoneNumber}
            className={classes("phone-input form-control text-left pr-0", {
              'border-danger' : error,
              disabled,
            })}
            international
            onChange={handleChange}
            onCountryChange={onCountryChange}
            // inputComponent={Input}
          /> : <ReactPhoneInputInput
            withCountryCallingCode
            placeholder="Enter phone number"
            defaultCountry="GH"
            country={countryCode}
            countries={countries}
            disabled={disabled || (!countryEditable && isEmpty(country))}
            value={phoneNumber}
            inputMode={"tel"}
            className={classes("phone-input form-control text-left pr-0", {
              'border-danger' : error,
              disabled,
            })}
            international
            onChange={handleChange}
          />
      }
    </InputGroup>
    <style global jsx>{`
      .PhoneInputCountry {
        //margin-left: 7px;
        padding-right: 6px;
        border-right: 1px solid transparentize(#bf9050, .7);
      }

      .PhoneInputInput {
        padding: 0.375rem 0.75rem;
        padding-left: 0;
        height: 35px;
        border-color: transparent;
        background-color: #fff;

        &:focus {
          border: 1px solid transparentize(#bf9050, .7);
        }
      }
    `}</style>
  </div>);
};

PhoneInput.propTypes = {};

export default PhoneInput;
