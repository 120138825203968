/**
 * A select dropdown data input component that allows typing the name of an item in the list
 *
 * @version 1.0.1
 * @author [George Koomson](https://github.com/archx3) [kobina.me](http://kobina.me)
 * @copyright (c) 2020, Vodafone Ghana and the authors
 * @link{DatePicker.md}
 */
import React, { Component, createRef } from 'react';
import moment from 'moment';
import classes from 'classnames'
import PropTypes from 'prop-types';
import { Col, FormGroup, InputGroup } from "reactstrap";
import Calendar from 'react-calendar';
import EventOutsideNotifier from "../../higher-order-components/EventOutsideNotifier";
import CalendarIcon from "./CalendarIcon";
import { isEmpty } from "../../helpers/utils";

class DatePicker extends Component {
  textInput = createRef(null);
  calendarContainer = createRef(null);

  constructor (props) {
    super(props);

    this.state = {
      defaultSet : false,
      inputText : '',
      keyboardSelection : -1,
      loaded : false,
      placeholder : '',
      selected : null,
      showCalendar : false,
      startDate : props.startDate || new Date(),
      value : props.value || "",
    };
  }

  innerHeight = 0;

  componentDidMount () {
    this.innerHeight = window.innerHeight;
    if (!isEmpty(this.props.defaultDate)) {
      this.setDefault(); //FIXME deal with setting default value
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.defaultDate !== this.props.defaultDate) {
      this.setDefault();
    }
  }

  setDefault () {
    this.setDate(this.props.defaultDate);
    this.setState({ defaultSet : true });
  }

  setDate (date) {
    const { name, onChange } = this.props;
    date = moment(date).format("YYYY-MM-DD");


    this.setState({ inputText : date });
    if (onChange && typeof onChange === "function") {
      onChange(name, date, this.textInput?.current);
    }
  }

  /**
   * Goes
   * and Sets the {@property state.showCalendar}
   * and causes the view to react to the new data
   * @returns void
   */
  showCalendar () {
    this.setState({ showCalendar : true }, this.reposition);
  }

  hideCalendar () {
    if (this.state.showCalendar === true) {
      this.setState({ showCalendar : false });
    }
    const calendarContainer = this.calendarContainer?.current;
    calendarContainer.style.bottom = "";
    calendarContainer.style.boxShadow = "";
  }


  reposition () {
    const calendarContainer = this.calendarContainer?.current;
    let { bottom } = calendarContainer.getBoundingClientRect();

    if (bottom > this.innerHeight) {
      calendarContainer.style.bottom = "39px";
      calendarContainer.style.boxShadow = "0 -2px 4px rgba(98, 98, 98, 0.25)";
    }
  }

  /**
   * let's set up 2 way data-binding for our text box input
   * @param event {Event}
   */
  handleChange (event) {
    let inputText = event.target.value.trim().toLowerCase();

    this.setState({ inputText }, function () {
      if (isEmpty(this.state.inputText)) {
      }
    });
  }

  handleCalendarChange (newDate,clickEventType) {
    this.setDate(newDate);
    if(clickEventType==="change"){
       this.hideCalendar();
    }
  }

  /**
   * if the text box input goes out of focus and the text in it doesn't match any in our,
   * let's clear it, so they know they haven't selected anything
   * @param event {Event}
   */
  handleBlur (event) {
    let inputText = event.target.value;
    let state = this.state;
    let isDate = inputText.trim().toLowerCase(); // FIXME here check if input text is date

    let text = (isDate || state.showCalendar === true) ? state.inputText : '';

    this.setState({
      inputText : text,
      value : text,
    })
  }

  handleNavigationKey (e) {
    if (this.state.showCalendar) {
      if ("ArrowUp" === e.key) {
        this.hideCalendar();
      }
    } else {
      if (e.key === "ArrowDown") {
        this.showCalendar();
      }
    }
  }

  render () {
    let state = this.state;
    let { placeholder, disabled, className, id, label, maxDate = null, startDate = null } = this.props;
    let { showCalendar, inputText, selected, keyboardSelection } = state;

    placeholder = placeholder ? placeholder : state.placeholder;

    if (maxDate) {
      maxDate = new Date(maxDate);

      if (!startDate) {
        startDate = maxDate;
      }
    } else if (startDate) {
      startDate = new Date(startDate);
    }

    let calButton = (
      <button
        data-testid="calendar-toggle-button"
        type={"button"}
        aria-roledescription="dropdown button"
        className={classes("btn rounded-0 combo-btn d-chevron col-2 ", { disabled })}
        onClick={() => {
          if (state.showCalendar === true) {
            this.hideCalendar();
          } else {
            this.showCalendar();
          }
          this.textInput?.current?.focus()
        }}>
        <CalendarIcon className={'icon-fill'}/>
      </button>
    );

    return (
      <EventOutsideNotifier 
      event={"click"} 
      handler={this.hideCalendar.bind(this)}
      className={classes("calendar DatePicker position-relative ", className, {})}
      >
        <FormGroup className="mb-0 input " data-testid={"date-picker"}>
          <InputGroup
            className={classes("input-group-alternative form-control-wrapper", className, {
              disabled,
              focus : showCalendar
            })}>
            <input
              id={id}
              ref={this.textInput}
              data-testid="datepicker-input"
              className={"text-input date-picker form-control rounded-left" + (disabled ? " disabled" : "")}
              aria-label={label} placeholder={placeholder}
              value={inputText}
              type="text" style={{ borderRadius : "0" }}
              readOnly={true}
              onChange={(e) => {
                this.handleChange(e)
              }}
              onBlur={(e) => {
                this.handleBlur(e)
              }}
              onKeyUp={(e) => {
                this.handleNavigationKey(e)
              }}
              onClick={() => {
                if (showCalendar === true) {
                  this.hideCalendar();
                } else {
                  this.showCalendar();
                }
                this.textInput?.current?.focus()
              }}
            />
            {calButton}
          </InputGroup>
        </FormGroup>
        <div
          ref={this.calendarContainer}
          data-testid="datepicker-calendar-container"
          className={classes("row position-absolute calendar-container", { 'd-none' : !showCalendar })}
        >
          <Col onClick={(event)=>event.stopPropagation()} sm="12" className="" data-testid="datepicker-calendar">
            <Calendar
              onClickDay={(newDate) => this.handleCalendarChange(newDate, 'selection')}
              onClickMonth={(newDate) => this.handleCalendarChange(newDate, 'selection')}
              onClickYear={(newDate) => this.handleCalendarChange(newDate, 'selection')}
              className="input-sibling m-auto p-3"
              maxDate={startDate}
              defaultActiveStartDate={startDate}
              value={inputText === "" ? "" : new Date(inputText)}
              onChange={(newDate) => this.handleCalendarChange(newDate, 'change')}
              calendarType="US"
            />
          </Col>
        </div>
      </EventOutsideNotifier>
    )
  }
}

DatePicker.defaultProps = {
  className : '',
  defaultDate : undefined,
  disabled : false,
  preloaded : false,
  tabIndex : null,
};

DatePicker.propTypes = {
  className : PropTypes.string,
  defaultDate : PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  disabled : PropTypes.bool,
  id : PropTypes.string,
  keyboardSupport : PropTypes.bool,
  maxDate : PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  onChange : PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]),
  placeholder : PropTypes.string,
  preloaded : PropTypes.bool,
  startDate : PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  tabIndex : PropTypes.number,
};

export default DatePicker;
